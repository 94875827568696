import { useState, useEffect, useCallback } from 'react'
import Image from 'next/legacy/image'
import Link from 'next/link'
import styles from './slidemenu.module.scss'
import crocLogo from '@public/images/logo-white.png'
import Icons from '@components/Icons'
import type { navSection } from '@lib/types'
import { NAVSECTIONS } from '@lib/navdata'


const SlideMenu = ({ onMouseDown, menuVisibility } : {
    onMouseDown: (e: any) => void,
    menuVisibility: boolean
}) => {

    const [visibility, setVisibility] = useState(styles.hide);

    useEffect(() => {
        (menuVisibility) ? setVisibility(styles.show) : setVisibility(styles.hide);
    }, [menuVisibility])

    // creates navlinks from provided data
    const renderMenuItems = useCallback((data : navSection[]) => {
        const html: any[] = [];
        data.forEach((section, index) => {
            const links: any[] = [];
            section.navLinks.forEach((link, index) => {
                links.push(
                    <li key={index}>
                        <Link href={link.path}>
                            {link.title}
                        </Link>
                    </li>
                )
            });

            html.push(
                <li className={styles.menuGroup} key={index}>
                    <Link href={section.path} className={styles.titleLink}>

                        <h2>{section.title}</h2>

                    </Link>
                    <ul className="gibson-1">
                        {links}
                    </ul>
                </li>
            )
        });

        return (
            <ul className={styles.menuitems}>
                {html}
            </ul>
        )
    }, [])

    return (
        <div className={`${visibility} ${styles.container}`}>
            <div className={`${visibility} ${styles.themenu}`} onClick={(e) => {onMouseDown(e);}}>
                <button aria-label="Close Menu" className={styles.close} 
                onClick={(e) => {
                    onMouseDown(e);
                }}>
                    <Icons iconClass={`nav-x`} extraClass={`hover`} />
                </button>
                <div className={styles.logo}>
                    <Link href="/">

                        <Image 
                            priority
                            src={crocLogo}
                            width={150}
                            height={35}
                            alt="Crocodile Hunter Lodge Logo"
                            placeholder='blur'
                        />

                    </Link>
                </div>
                {renderMenuItems(NAVSECTIONS)}
            </div>
            <div className={`${visibility} ${styles.clickoff}`} onClick={(e) => {onMouseDown(e)}}/>
        </div>
    );
}

export default SlideMenu